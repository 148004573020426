import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoMapfre from "../../assets/old/img/logos-ase/ico-mapfre.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="mapfre">
        <Helmet>
            <title>Experta Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia: 0800-7777-278."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoMapfre}
                    alt="Experta Seguros"
                />
                <h4 className="lead post-title text-muted">Experta Seguros</h4>
                <p className="">Servicio de asistencia: Serivicio de asistencia: 0800-7777-278.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
            Si tuviste un imprevisto con tu automóvil te presentamos una guía paso a paso para denunciar tu siniestro
        </p>
        <h6>¿Qué hago si tuve un siniestro con mi auto?</h6>
        <p>
            01. Solicitar al otro conductor sus datos personales, del vehículo y de su seguro.
        </p>
        <p>
            02. Si hubieron personas heridas o lesionadas o robo total de la unidad, también tenés que hacer la denuncia policial de inmediato.
        </p>
        <p>
            03. Revisá en las condiciones de tu póliza, si la misma te cubre por daños parciales. En este caso, la compañía debe inspeccionar el automóvil antes que hagas la reparación del mismo. Si tu póliza no cubre los daños de tu auto, comunícate con la compañía de seguros del otro conductor. Si recibís notificación de demanda judicial y/o mediación, debés presentarla a la brevedad en la oficina comercial MAPFRE más cercana.
        </p>

        <hr className="pb-4" />



    </SiniestrosLayout>
)
